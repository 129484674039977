const SocialIconsViewModel = [
    { name: 'github', link: 'https://github.com/vinicius-stutz', title: 'Dê um fork nos meus projetos no Github', external: true, class: 'text-white' },
    { name: 'linkedin', link: 'https://www.linkedin.com/in/viniciusstutz/', title: 'Conecte-se comigo no Linkedin', external: true, class: 'text-white' },
    { name: 'code-square', link: 'https://dev.to/stutz', title: 'Reaja aos meus artigos na comunidade', external: true, class: 'text-white' },
    { name: 'medium', link: 'https://medium.com/@vinicius_stutz', title: 'Onde escrevo algumas coisas', external: true, class: 'text-white' },
    { name: 'stack-overflow', link: 'https://stackoverflow.com/users/1614661/vinicius-stutz', title: 'Profile at Stack Overflow, Q&amp;A for professional and enthusiast programmers', external: true, class: 'text-white' },
    { name: 'box', link: 'https://codepen.io/vinicius-stutz', title: 'Veja meus "pens" no Codepen', external: true, class: 'text-white' },
    { name: 'behance', link: 'https://www.behance.net/vinicius-stutz', title: 'Em que tenho trabalhado', external: true, class: 'text-white' },
    { name: 'twitter', link: 'https://twitter.com/vinicius_stutz', title: 'Siga-me no ✕ (antigo Twitter)', external: true, class: 'text-white' },
    // { name: 'facebook', link: 'https://www.facebook.com/vini.stutz', title: 'Curta a página no Facebook', external: true, class: 'text-white' },
    // { name: 'instagram', link: 'https://www.instagram.com/vini.stutz/', title: 'Siga-me no Instagram', external: true, class: 'text-white' },
    // { name: 'reddit', link: 'https://www.reddit.com/user/vinicius-stutz/', title: 'Comunidades no reddit', external: true, class: 'text-white' },
    { name: 'youtube', link: 'https://www.youtube.com/@ViniciusStutz/featured', title: 'Inscreva-se no meu canal do Youtube', external: true, class: 'text-white' },
    // { name: 'vimeo', link: 'https://vimeo.com/stutz', title: 'Siga-me na plataforma do Vimeo', external: true, class: 'text-white' }
];

export default SocialIconsViewModel;