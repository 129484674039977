import ThemeViewModel from "./ThemeViewModel";

const TermsViewModel = [{
    theme: ThemeViewModel.light,
    name: 'Termos de uso',
    htmlText: <>
        <h3>O que você vai ver aqui?</h3>
        <p className='lh-base'>Este documento descreve os <strong>termos e condições</strong> para uso deste Site, definindo a licença de uso, os comportamentos aceitáveis, as informações sobre serviços de terceiros, a hospedagem e tecnologia utilizada, e uma isenção de responsabilidade.</p>
        <hr className="mt-5 mb-5" />
        <h3>Disposições gerais</h3>
        <p className='lh-base'>Estes termos e condições estabelecem as regras e regulamentos para o uso dos serviços oferecidos por este Site.</p>
        <p className='lh-base'>Ao acessar este site, entende-se que você aceita estes termos e condições por completo. Se você não concordar com todos os termos e condições aqui estabelecidos, não continue a usá-lo.</p>
        <p className='lh-base'>A terminologia a seguir se aplica a estes Termos e Condições, Declaração de Privacidade e Aviso de Isenção de Responsabilidade, bem como a quaisquer acordos: "Usuário", "Você" e "Seu" referem-se a você, a pessoa que está acessando este site e aceitando os termos e condições da empresa. "O Vinícius", "Eu", "Meu", "Este", "Neste", "Deste" e "Comigo" referem-se a este Site. "Partes" refere-se tanto ao Usuário quanto a mim. Estes termos se referem à oferta, aceitação e, em casos necessários, consideração de pagamento para realizar a assistência prestada ao Usuário da maneira mais apropriada possível.</p>
        <h3>Licença</h3>
        <p className='lh-base'>Salvo indicação em contrário, sou o proprietário dos direitos de propriedade intelectual de todo o material contido neste Site. Todos os direitos de propriedade intelectual são reservados. Você pode visualizar e/ou imprimir páginas deste Site apenas para uso pessoal, sujeito às restrições estabelecidas nestes termos e condições.</p>
        <h3>Uso Aceitável</h3>
        <p className='lh-base'>Você não deve:</p>
        <ul>
            <li>Utilizar este Site de forma que cause ou possa causar danos, ou prejudicar a disponibilidade, ou acessibilidade dele.</li>
            <li>Utilizar este Site de forma ilícita, ilegal, fraudulenta ou prejudicial, ou em conexão com qualquer finalidade, ou atividade ilícita, ilegal, fraudulenta ou prejudicial.</li>
            <li>Utilizar este Site para copiar, armazenar, hospedar, transmitir, enviar, usar, publicar ou distribuir qualquer material que consista em (ou esteja vinculado a) qualquer spyware, vírus de computador, cavalo de Troia, worm, keylogger ou outro software malicioso de computador.</li>
            <li>Realizar qualquer atividade sistemática ou automatizada de coleta de dados (incluindo, mas não se limitando a, raspagem, mineração de dados, extração de dados e colheita de dados) no site ou em relação a ele.</li>
        </ul>
        <h3>Serviços de Terceiros</h3>
        <p className='lh-base'>Este site utiliza vários serviços de terceiros para melhorar a experiência do usuário e a funcionalidade. Ao utilizar este site, você concorda com os termos e condições desses serviços de terceiros, incluindo, entre outros:</p>
        <ul>
            <li>Google™ Analytics: Utilizado para analisar o tráfego do site e o comportamento do usuário. Consulte os termos de serviço e a política de privacidade do Google para obter mais informações.</li>
            <li>Tawk.io: Utilizado para suporte de chat em tempo real. Consulte os termos de serviço e a política de privacidade do Tawk.io para obter mais informações.</li>
            <li>Jotform: Utilizado para a funcionalidade de formulário de contato. Consulte os termos de serviço e a política de privacidade do Jotform para obter mais informações.</li>
            <li>Raindrop: Utilizado para compartilhar links favoritos. Consulte os termos de serviço e a política de privacidade do Raindrop para obter mais informações.</li>
        </ul>
        <h3>Hospedagem e Tecnologia</h3>
        <p className='lh-base'>Este Site é hospedado na plataforma de hospedagem do Google™. O site foi criado usando frameworks e bibliotecas open source, como ReactJS e Bootstrap. Ao utilizar este site, você reconhece e concorda em seguir os termos de serviço e a política de privacidade do Google.</p>
        <h3>Isenção de Responsabilidade</h3>
        <p className='lh-base'>Até o limite máximo permitido por lei, excluímos todas as representações, garantias e condições relacionadas a este Site e ao uso deste Site.</p>
        <hr />
        <small>Data da última atualização: 01 de abril de 2024</small>
    </>
}];

export default TermsViewModel;