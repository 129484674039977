import React from 'react';
import HomeViewModel from '../model/HomeViewModel';
import PageTemplate from '../components/templates/PageTemplate';
import ThemeViewModel from '../model/ThemeViewModel';

function Home() {
    return <PageTemplate menuTheme={ThemeViewModel.light} hasHeader={true} sections={HomeViewModel} />;
}

export default Home;
