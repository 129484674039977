import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function AlertMessage({ title, message, variant, show, onClose }) {
    return (
        <Alert show={show} variant={variant} onClose={onClose} dismissible className='p-3 z-3 position-fixed bottom-0 start-0 ms-5 mb-5 shadow-lg'>
            <Alert.Heading>{title}</Alert.Heading>
            {message}
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={onClose} variant={`outline-${variant}`}>
                    Entendi
                </Button>
            </div>
        </Alert>
    );
}

export default AlertMessage;