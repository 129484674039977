import React from 'react';
import Section from '../atoms/Section';

function Sections({ sections }) {
    return (
        <>
            {sections.map((s, index) => (
                <Section
                    key={index}
                    id={s.id}
                    theme={s.theme}
                    name={s.name}
                    img={s.img}
                    htmlText={s.htmlText}
                    extraHtml={s.extraHtml} />
            ))}
        </>
    );
}

export default Sections;