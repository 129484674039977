import React from 'react';
import PageTemplate from '../components/templates/PageTemplate';
import ContactViewModel from '../model/ContactViewModel';
import ThemeViewModel from '../model/ThemeViewModel';

function Contact() {
    return <PageTemplate menuTheme={ThemeViewModel.dark} sections={ContactViewModel} />;
}

export default Contact;
