import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import '../../styles/Loading.scss';

function Loading({ label, isFixed }) {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (!isFixed) {
            document.onreadystatechange = () => {
                if (document.readyState === 'complete') {
                    setTimeout(() => {
                        setIsVisible(false);
                    }, 1000);
                }
            };

            return () => document.onreadystatechange = null;
        }
    }, [isFixed]);

    return (
        <div className={`loader-spinner opacity-50 rounded bg-black ps-2 pe-2 pt-1 pb-2 position-absolute top-0 start-50 translate-middle-x ${isFixed ? 'z-n1' : isVisible ? 'z-3' : 'animate__animated animate__fadeOut'}`}>
            <Spinner animation="grow" role="status" size="sm" />
            {label && <span className="ms-2">{label}</span>}
        </div>
    );
}

export default Loading;
