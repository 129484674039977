import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Footer from '../organisms/Footer'
import Sections from '../organisms/Sections';
import ThemeViewModel from '../../model/ThemeViewModel';
import SocialIconsViewModel from '../../model/SocialIconsViewModel';
import Header from '../organisms/Header';
import MainMenu from '../organisms/MainMenu';
import AlertMessage from '../organisms/AlertMessage';
import Icon from '../atoms/Icon';
import Cookies from 'js-cookie';

function PageTemplate({ menuTheme, hasHeader, sections }) {
    const [show, setShowAlert] = useState(false);

    useEffect(() => {
        setShowAlert(Cookies.get('dmluaWNpLnVzIGNvb2tpZSBjb25zZW50') !== 'false');
    }, []);

    const handleCloseAlert = () => {
        setShowAlert(false);
        Cookies.set('dmluaWNpLnVzIGNvb2tpZSBjb25zZW50', 'false', { expires: 365 });
    };

    return (
        <>
            <AlertMessage
                title={<span>Controle sua privacidade <Icon name={'cookie'} /></span>}
                message={<p>Este site utiliza <abbr title='Um cookie, no âmbito do protocolo de comunicação HTTP usado na Internet, é um pequeno arquivo de computador ou pacote de dados enviados por um site de Internet para o navegador do usuário, quando o utilizador visita o site.'>cookies</abbr> com a finalidade de melhorar a experiência durante a navegação. Ao continuar navegando, você automaticamente aceita as <Link to={'/Privacy'}>políticas</Link> e <Link to={'/Terms'}>termos</Link> deste site.</p>}
                variant={'info'}
                show={show}
                onClose={handleCloseAlert} />
            <Container fluid className='p-0'>
                {menuTheme !== null ? <MainMenu theme={menuTheme} /> : null}

                {hasHeader ? <Header /> : <div className={`pt-5 ${menuTheme === ThemeViewModel.dark ? 'bg-black' : ''}`}></div>}

                <Sections sections={sections} />

                <Footer theme={ThemeViewModel.dark} icons={SocialIconsViewModel} />
            </Container>
        </>
    );
}

export default PageTemplate;