import { Button, Col, Row } from "react-bootstrap";
import ThemeViewModel from "./ThemeViewModel";

const OpenSourceViewModel = [{
    theme: ThemeViewModel.dark,
    name: 'Open Source. Nem tão open, nem tanto source. Ainda!',
    htmlText: <>
        <p className='lh-base'>Gostaria de compartilhar com vocês meus projetos open source. Tenho trabalhado bem devagar ao longo dos anos em várias ideias inovadoras e realmente quero compartilhar meu conhecimento com a comunidade.</p>
        <p className='lh-base'>No entanto, por conta da correria do dia a dia, os meus projetos estão um pouco, digamos, "espalhados" em diferentes plataformas. No GitHub tenho tanto repositórios como gists, alguns drives em nuvens e até mesmo algumas brincadeiras no CodePen. Podemos dizer que a dispersão é a palavra-chave aqui.</p>
        <p className='lh-base'>Apesar dessa pequena "desorganização", tenho tentado colocar esses projetos open source em ordem. Para que minhas contribuições sejam realmente valiosas, é fundamental ter uma estrutura bem definida e repositórios centralizados.</p>
        <p className='lh-base'>Cada link selecionado abrirá em nova aba.</p>
        <Row>
            <Col sm={12} md={4} className="text-center">
                <Button href="https://codepen.io/vinicius-stutz" variant="primary" size="lg" rel="noopener noreferrer" target="_blank" className="mt-5">
                    <i className="bi bi-box"></i> Codepen<br />
                    <small>Playground front-end</small>
                </Button>
            </Col>
            <Col sm={12} md={4} className="text-center">
                <Button href="https://github.com/vinicius-stutz" variant="primary" size="lg" rel="noopener noreferrer" target="_blank" className="mt-5">
                    <i className="bi bi-github"></i> Github<br />
                    <small>Repositórios back-end</small>
                </Button>
            </Col>
            <Col sm={12} md={4} className="text-center">
                <Button href="https://gist.github.com/vinicius-stutz" variant="primary" size="lg" rel="noopener noreferrer" target="_blank" className="mt-5">
                    <i className="bi bi-github"></i> Gists<br />
                    <small>Um blog de códigos?</small>
                </Button>
            </Col>
        </Row>
        <p className='lh-base mt-5'>Também escrevo (ou tento escrever) alguns artigos nas plataformas digitais a seguir:</p>
        <Row>
            <Col sm={12} md={6} className="text-center">
                <Button href="https://dev.to/stutz" variant="primary" size="lg" rel="noopener noreferrer" target="_blank" className="mt-5">
                    <i className="bi bi-code-square"></i> Dev.to<br />
                    <small>Artigos (super) tech</small>
                </Button>
            </Col>
            <Col sm={12} md={6} className="text-center">
                <Button href="https://medium.com/@vinicius_stutz" variant="primary" size="lg" rel="noopener noreferrer" target="_blank" className="mt-5">
                    <i className="bi bi-medium"></i> Medium<br />
                    <small>Artigos (nada) tech</small>
                </Button>
            </Col>
        </Row>
    </>
}];

export default OpenSourceViewModel;