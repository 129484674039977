import { Col, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import BookmarksLinks from "../components/organisms/BookmarksLinks";
import ThemeViewModel from "./ThemeViewModel";
import Lists from "../utils/Lists";

let devLinks = [{
    name: 'database-fill',
    link: 'https://raindrop.io/vinicius-stutz/bancos-de-dados-38523587',
    text: 'Banco de dados'
}, {
    name: 'braces',
    link: 'https://raindrop.io/vinicius-stutz/dev-net-38523593',
    text: 'Desenvolvimento .NET'
}, {
    name: 'braces-asterisk',
    link: 'https://raindrop.io/vinicius-stutz/dev-apis-38523604',
    text: 'APIs'
}, {
    name: 'body-text',
    link: 'https://raindrop.io/vinicius-stutz/dev-artigos-38523605',
    text: 'Artigos sobre desenvolvimento'
}, {
    name: 'filetype-css',
    link: 'https://raindrop.io/vinicius-stutz/dev-css-38523610',
    text: 'CSS, SCSS e SASS'
}, {
    name: 'file-earmark-code',
    link: 'https://raindrop.io/vinicius-stutz/dev-conversores-de-codigos-38523606',
    text: 'Conversores de código'
}, {
    name: 'phone-fill',
    link: 'https://raindrop.io/vinicius-stutz/dev-dispositivos-moveis-38523687',
    text: 'Desenvolvimento mobile'
}, {
    name: 'journal-code',
    link: 'https://raindrop.io/vinicius-stutz/dev-documentacao-38523608',
    text: 'Documentação (dev.)'
}, {
    name: 'bullseye',
    link: 'https://raindrop.io/vinicius-stutz/dev-driven-development-38523622',
    text: 'DDD'
}, {
    name: 'code-slash',
    link: 'https://raindrop.io/vinicius-stutz/dev-ferramentas-front-end-38523623',
    text: 'Ferramentas para front-end'
}, {
    name: 'building-fill',
    link: 'https://raindrop.io/vinicius-stutz/dev-ibm-38523649',
    text: 'Tecnologias IBM'
}, {
    name: 'asterisk',
    link: 'https://raindrop.io/vinicius-stutz/dev-jwt-38523686',
    text: 'JWT'
}, {
    name: 'cup-hot-fill',
    link: 'https://raindrop.io/vinicius-stutz/dev-java-38523650',
    text: 'Java'
}, {
    name: 'filetype-js',
    link: 'https://raindrop.io/vinicius-stutz/dev-javascript-38523651',
    text: 'Javascript'
}, {
    name: 'plugin',
    link: 'https://raindrop.io/vinicius-stutz/dev-javascript-plugins-38523656',
    text: 'Plugins para Javascript'
}, {
    name: 'window-fullscreen',
    link: 'https://raindrop.io/vinicius-stutz/dev-low-code-38523868',
    text: 'Low-code'
}, {
    name: 'filetype-php',
    link: 'https://raindrop.io/vinicius-stutz/dev-php-38523692',
    text: 'PHP'
}, {
    name: 'box2-fill',
    link: 'https://raindrop.io/vinicius-stutz/dev-packages-38523691',
    text: 'Gerenciadores de pacotes'
}, {
    name: 'balloon-fill',
    link: 'https://raindrop.io/vinicius-stutz/dev-playground-and-sandboxes-38523693',
    text: 'Playground e sandboxes'
}, {
    name: 'filetype-py',
    link: 'https://raindrop.io/vinicius-stutz/dev-python-38523694',
    text: 'Python'
}, {
    name: 'stack',
    link: 'https://raindrop.io/vinicius-stutz/dev-queue-38523592',
    text: 'Filas e mensageria'
}, {
    name: 'regex',
    link: 'https://raindrop.io/vinicius-stutz/dev-regex-38523696',
    text: 'Regex'
}, {
    name: 'people-fill',
    link: 'https://raindrop.io/vinicius-stutz/dev-sdk-redes-sociais-38523698',
    text: 'SDK para redes sociais'
}, {
    name: 'tools',
    link: 'https://raindrop.io/vinicius-stutz/dev-utilidades-38523699',
    text: 'Utilitários'
}, {
    name: 'git',
    link: 'https://raindrop.io/vinicius-stutz/dev-versionamento-38523807',
    text: 'Controle de versão'
}, {
    name: 'filetype-yml',
    link: 'https://raindrop.io/vinicius-stutz/dev-ops-38523607',
    text: 'DevOps'
}];

const freebiesLinks = [{
    name: 'images',
    link: 'https://raindrop.io/vinicius-stutz/freebies-banco-de-imagens-38523733',
    text: 'Banco de imagens'
}, {
    name: 'fonts',
    link: 'https://raindrop.io/vinicius-stutz/freebies-fontes-38523730',
    text: 'Fontes'
}, {
    name: 'window-sidebar',
    link: 'https://raindrop.io/vinicius-stutz/freebies-ux-and-design-38523802',
    text: 'UX e Design'
}, {
    name: 'person-video2',
    link: 'https://raindrop.io/vinicius-stutz/freebies-videos-38523731',
    text: 'Vídeos'
}, {
    name: 'emoji-smile',
    link: 'https://raindrop.io/vinicius-stutz/freebies-icones-38523732',
    text: 'Pacotes de ícones'
}, {
    name: 'cash-coin',
    link: 'https://raindrop.io/vinicius-stutz/patrocinio-and-doacao-42114241',
    text: 'Patrocínio e doação'
}, {
    name: 'easel2-fill',
    link: 'https://raindrop.io/vinicius-stutz/portfolio-38523842',
    text: 'Portfolio'
}, {
    name: 'file-music-fill',
    link: 'https://raindrop.io/vinicius-stutz/freebies-sons-e-musicas-43071265',
    text: 'Sons e músicas'
}];

let toolsLinks = [{
    name: 'kanban-fill',
    link: 'https://raindrop.io/vinicius-stutz/boards-and-kanban-38523777',
    text: 'Boards e Kanban'
}, {
    name: 'clipboard2-data-fill',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-analise-and-monitoramento-38523826',
    text: 'Análise e monitoramento'
}, {
    name: 'headset',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-atendimento-ao-cliente-38523831',
    text: 'Atendimento ao cliente'
}, {
    name: 'share-fill',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-colaboracao-38523832',
    text: 'Colaboração'
}, {
    name: 'file-earmark-break',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-conversores-de-arquivos-38523851',
    text: 'Conversor de arquivos'
}, {
    name: 'bezier2',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-desenho-grafico-38523854',
    text: 'Desenho gráfico'
}, {
    name: 'envelope-at-fill',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-e-mail-marketing-38523834',
    text: 'E-mail marketing'
}, {
    name: 'receipt-cutoff',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-faturamento-and-vendas-38523828',
    text: 'Faturamento e vendas'
}, {
    name: 'megaphone-fill',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-feedback-do-usuario-38523847',
    text: 'Feedback do usuário e pós-vendas'
}, {
    name: 'pc-display',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-it-service-management-38523838',
    text: 'IT Service Management'
}, {
    name: 'image-fill',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-imagens-38523856',
    text: 'Imagens'
}, {
    name: 'file-bar-graph-fill',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-infograficos-38523857',
    text: 'Infográficos'
}, {
    name: 'marker-tip',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-marketing-38523840',
    text: 'Marketing'
}, {
    name: 'node-plus-fill',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-mind-maps-38523841',
    text: 'Mind maps'
}, {
    name: 'briefcase-fill',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-negocios-38523825',
    text: 'Negócios'
}, {
    name: 'file-earmark-word-fill',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-office-38523858',
    text: 'Suítes Office'
}, {
    name: 'bar-chart-steps',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-project-management-38523844',
    text: 'Project Management'
}, {
    name: 'fullscreen',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-screenshot-38523863',
    text: 'Capturas de tela'
}, {
    name: 'input-cursor-text',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-service-desk-38523845',
    text: 'Service Desk'
}, {
    name: 'stopwatch-fill',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-time-tracking-38523846',
    text: 'Time Tracking'
}, {
    name: 'film',
    link: 'https://raindrop.io/vinicius-stutz/ferramentas-videos-38523866',
    text: 'Vídeos'
}, {
    name: 'diagram-3-fill',
    link: 'https://raindrop.io/vinicius-stutz/diagramas-38523869',
    text: 'Diagramas'
}]

let utilsLinks = [{
    name: 'person-badge-fill',
    link: 'https://raindrop.io/vinicius-stutz/carreira-38523830',
    text: 'Carreira'
}, {
    name: 'cloud-fill',
    link: 'https://raindrop.io/vinicius-stutz/cloud-application-platform-38523714',
    text: 'Cloud Application Platform'
}, {
    name: 'hdd-network-fill',
    link: 'https://raindrop.io/vinicius-stutz/cloud-drives-38523583',
    text: 'Cloud Drives'
}, {
    name: 'qr-code',
    link: 'https://raindrop.io/vinicius-stutz/codigos-de-barras-38523822',
    text: 'Código de barras'
}, {
    name: 'link-45deg',
    link: 'https://raindrop.io/vinicius-stutz/encurtador-de-urls-38523864',
    text: 'Encurtador de URLs'
}, {
    name: 'robot',
    link: 'https://raindrop.io/vinicius-stutz/ia-38523812',
    text: 'Inteligência artificial'
}, {
    name: 'stickies-fill',
    link: 'https://raindrop.io/vinicius-stutz/notas-38523778',
    text: 'Notas'
}, {
    name: 'person-workspace',
    link: 'https://raindrop.io/vinicius-stutz/ofertas-de-trabalho-freelance-38523836',
    text: 'Ofertas de trabalho (freelance)'
}];

const BookmarksViewModel = [
    {
        theme: ThemeViewModel.light,
        name: "Coleções",
        htmlText: (
            <>
                <p className="lh-base">
                    Costumo ouvir perguntas sobre usos específicos no meu
                    dia-a-dia. Sendo assim, apresento aqui uma coleção orgânica
                    de links que utilizo quase que diariamente e são alimentadas
                    diretamente por mim via{" "}
                    <abbr title="Um site de favoritos muito útil para quem gosta de manter as coisas organizadas">
                        Raindrop.io
                    </abbr>.
                </p>
                <p className="lh-base">
                    <abbr title="Observação">OBS</abbr>: Cada opção escolhida abrirá em uma nova aba do seu
                    navegador.
                </p>
                <Row>
                    <Col>
                        <iframe
                            title="Minha prórpia 🕶️ awesome list"
                            id="raindropFrame"
                            style={{
                                'border': 0,
                                'width': '100%',
                                'height': '6100px',
                                'overflow': 'none'
                            }}
                            allowFullScreen
                            frameBorder="0"
                            src="https://raindrop.io/vinicius-stutz/embed/me/theme=light&no-header=true"
                        >
                        </iframe>
                        {/* <Accordion className="mt-5 mb-5">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><h5>Desenvolvimento<small>Dicas, plugins e artigos</small></h5></Accordion.Header>
                                <Accordion.Body>
                                    <BookmarksLinks links={Lists.sortByProp(devLinks, 'text')} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><h5>Ferramentas<small>Ajuda extra no dia-a-dia</small></h5></Accordion.Header>
                                <Accordion.Body>
                                    <BookmarksLinks links={Lists.sortByProp(toolsLinks, 'text')} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><h5>Freebies<small>Coisas gratuitas!</small></h5></Accordion.Header>
                                <Accordion.Body>
                                    <BookmarksLinks links={Lists.sortByProp(freebiesLinks, 'text')} />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header><h5>Útil<small>Utilidades gerais</small></h5></Accordion.Header>
                                <Accordion.Body>
                                    <BookmarksLinks links={Lists.sortByProp(utilsLinks, 'text')} />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> */}
                    </Col>
                </Row>
            </>
        ),
    },
];

export default BookmarksViewModel;
