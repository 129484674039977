import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ThemeViewModel from '../../model/ThemeViewModel';

function Section({ id, theme, name, img, htmlText, extraHtml }) {
    let bgClass = '';
    let flexClass = '';

    if (theme === ThemeViewModel.dark) {
        bgClass = 'bg-black';
    }

    if (img !== null) {
        flexClass = 'd-flex justify-content-center align-items-center'
    }

    return (
        <>
            <section id={id} className={`pt-5 pb-5 ${bgClass}`}>
                <Container>
                    <Row>
                        <Col><h2 className='mb-5'>{name}</h2></Col>
                        <Col lg xl xxl></Col>
                    </Row>
                    <Row>
                        {
                            img && <Col xs={12} md={12} lg={6} xl={4} className='d-flex justify-content-center align-items-center'>
                                <Image src={img} fluid />
                            </Col>
                        }
                        <Col>
                            <div className={flexClass}>
                                <div>{htmlText}</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {extraHtml}
        </>
    );
}

export default Section;