import React, { useEffect } from 'react';
import PageTemplate from '../components/templates/PageTemplate';
import BookmarksViewModel from '../model/BookmarksViewModel';
import '../styles/Bookmarks.scss';
import ThemeViewModel from '../model/ThemeViewModel';

function Bookmarks() {
    // useEffect(() => {
    //     const iframe = document.getElementById('raindropFrame');
    //     iframe.addEventListener('load', updateIframeHeight);
    //     window.addEventListener('resize', updateIframeHeight);
    //     return () => {
    //         iframe.removeEventListener('load', updateIframeHeight);
    //         window.removeEventListener('resize', updateIframeHeight);
    //     };
    // }, []);

    // function updateIframeHeight() {
    //     const iframe = document.getElementById('raindropFrame');
    //     const iframeContent = iframe.contentDocument || iframe.contentWindow.document;
    //     const contentHeight = iframeContent.documentElement.scrollHeight;
    //     iframe.style.height = `${contentHeight}px`;
    // }

    return <PageTemplate menuTheme={ThemeViewModel.light} sections={BookmarksViewModel} />;
}

export default Bookmarks;