import React from "react";
import Maps from '../components/atoms/Maps';
import ThemeViewModel from './ThemeViewModel';

const HomeViewModel = [{
    id: 'who',
    theme: ThemeViewModel.dark,
    name: 'Quem?',
    img: './images/who.svg',
    htmlText: <>
        <p className='lh-base'>Olá! Como eu ia dizendo acima, me chamo <strong>Vinícius <i className="bi bi-patch-check-fill text-primary"></i></strong> e sou um profissional que muitos gostam de chamar de <abbr title="Analista desenvolvedor front e back-end que conhece e faz de tudo um pouco">fullstack</abbr>.</p>
        <p className='lh-base'>Para mim é um prazer criar interfaces dinâmicas e intuitivas, então no front-end tenho domínio de <em>Javascript</em>, <em>SCSS</em>, <em>HTML5</em> e <em>CSS3</em>, além de frameworks como <em>ReactJS</em>, <em>jQuery</em> e <em>Bootstrap</em>.</p>
        <p className='lh-base'>Já no back-end, minha especialidade é o <em>C#</em> (ou C-Sharp), utilizando <em>API Core</em>, <em>Web API</em>, <em>ASP.Net MVC</em>, <em>Windows Forms</em> e <em>Xamarin Forms</em> com MVVM. Também tenho conhecimentos em bancos de dados relacionais como <em>Oracle</em>, RDS Aurora <em>PostgreSQL</em>, <em>MySQL</em>, <em>MariaDB</em> e <em>SQL Server</em>, além de não relacionais, como o <em>MongoDB</em>. Além disso, conheço técnicas de mensageria e filas para diversos tipos de aplicação, como <em>RabbitMQ</em> e <em>AWS SQS</em>.</p>
        <p className='lh-base'>Todo esse trabalho, exatamente como o meu slogan afirma, adotando um <strong>código limpo</strong>, <strong>elegante</strong> e <strong>coeso</strong>. Sou ainda adepto de soluções práticas como o desacoplamento de sistemas e criação de micro serviços.</p>
    </>
}, {
    id: 'what',
    theme: ThemeViewModel.light,
    name: 'O que?',
    img: null,
    htmlText: <>
        <div className="embed-video shadow-lg">
            <div className="embed-responsive embed-responsive-16by9 embed-video">
                <iframe className='video rounded-3' src="https://player.vimeo.com/video/927234239?h=d22a6e35bc&autoplay=0&pip=1&muted=1&mute=1&loop=1&autopause=0&color=ffffff&title=0&byline=0&portrait=0&badge=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" allowFullScreen title="Apresentação"></iframe>
            </div>
        </div>
    </>
}, {
    id: 'why',
    theme: ThemeViewModel.dark,
    name: 'Por que?',
    img: './images/why.svg',
    htmlText: <>
        <p className='lh-base'>Existem três bons motivos:</p>
        <ol>
            <li className='lh-base pb-3'>Sempre fui um apaixonado por tecnologia e gostava da possibilidade de criar soluções inovadoras para os problemas que eu via, decidindo então seguir carreira na área, formando-me e especializando-me em análise e desenvolvimento de sistemas. Hoje me sinto realizado e orgulhoso do meu trabalho, sei que contribuo para o desenvolvimento e para a melhoria na rotina dos meus usuários. Acredito que a tecnologia é uma ferramenta poderosa para transformar a realidade das empresas e que o analista de sistemas é um agente dessa transformação.</li>
            <li className='lh-base pb-3'>Gosto muito de criar soluções fluídas e rápidas, com interfaces agradáveis e intuitivas — o que um profissional dedicado, criativo e comprometido precisa entregar. Meu objetivo é mergulhar nos projetos com uma compreensão profunda do negócio e das maneiras como os produtos verdadeiramente irão ajudar o público-alvo.</li>
            <li className='lh-base'>O terceiro e último motivo é a análise do projeto em si. Pode parecer clichê, mas um produto digital requer todo o conhecimento para prever a maioria dos cenários possíveis, para que assim seja possível trabalhar sobre ele. Preparar os fluxos, enxergar o roteiro antes mesmo do desenvolvimento, implementar, testar e implantar.</li>
        </ol>
    </>
}, {
    id: 'when',
    theme: ThemeViewModel.light,
    name: 'Quando?',
    img: './images/when.svg',
    htmlText: <>
        <p className='lh-base'>Quando eu tinha mais ou menos uns 12 anos, meu pai me levou para o trabalho dele e me apresentou um computador chamado 486, da Intel.Fiquei fascinado pelo mundo da tecnologia e comecei a explorar os programas e jogos que podia acessar.No final dos anos 90 eu ganhei um K6-II 500, da AMD, aprendi a digitar, a navegar na internet e a usar e a criar pequenas soluções no Microsoft™ Access. Não deu outra, decidi seguir a carreira de analista de sistemas, pois me permitia unir minha paixão com minha profissão.</p>
        <p className='lh-base'>Com o passar do tempo, quis saber mais sobre como os computadores funcionavam. Descobri que podia criar meus próprios programas usando linguagens de programação e logo me interessei por <em>HTML</em>, <em>CSS</em> e <em>JavaScript</em>. Comecei a fazer páginas web simples. Logo depois aprendi sobre bancos de dados e logo estava realizando conexões entre minhas aplicações <em>Delphi</em> (ainda era da <em>Borland</em>) com bases <em>Paradox</em>. Busquei me aperfeiçoar e me atualizar constantemente, acompanhando as tendências e as demandas do mercado. Fiz cursos, certificações, projetos e participações em eventos da área. Também procurei trabalhar com diferentes tecnologias, tanto no <em>front-end</em> quanto no <em>back-end</em>, para ampliar meu conhecimento e minha versatilidade.</p>
    </>
}, {
    id: 'how',
    theme: ThemeViewModel.dark,
    name: 'Como?',
    img: './images/how.svg',
    htmlText: <>
        <p className='lh-base'>Por meio de uma comunicação fácil, rápida, direta e transparente.</p>
        <p className='lh-base'>Deixe sua mensagem no formulário de contato abaixo, ou converse comigo em tempo real <abbr title='Ícone azul no canto inferior direito da tela'>via chat</abbr>.</p>
        <div className="embed-responsive embed-responsive-16by9">
            <iframe
                id="JotFormIFrame-240568382333054"
                className="form"
                title="Formulário de contato"
                onLoad={window.parent.scrollTo(0, 0)}
                allowtransparency="true"
                allow="geolocation; microphone; camera; fullscreen"
                src="https://form.jotform.com/viniciusstutz/contato"
                frameBorder="0"
                scrolling="no"
            >
            </iframe>
        </div>
    </>
}, {
    id: 'where',
    theme: ThemeViewModel.light,
    name: 'Onde?',
    img: './images/where.svg',
    htmlText: <>
        <address>
            <h3>
                Rio de Janeiro — RJ<br />
                Brasil
            </h3>
        </address>
        <p className='lh-base'>Estou situado bem próximo a uma grande cidade brasileira à beira-mar, famosa pelas praias, pelo Cristo Redentor no topo do Corcovado e pelo Pão de Açúcar, um pico de granito com teleféricos até seu cume.</p>
        <p className='lh-base'>Próximo da cidade ainda temos uma região chamada de Grande Rio, que é a Região Metropolitana do Estado do Rio de Janeiro, onde eu vivo.</p>
    </>,
    extraHtml: <Maps url={'https://api.mapbox.com/styles/v1/vinicius-stutz/clu9w7wcu00gs01qte5jde7on.html?title=false&access_token=pk.eyJ1IjoidmluaWNpdXMtc3R1dHoiLCJhIjoiY2tnNzV0YXQ4MDJibTJ5cGRpcmp4ZzRwaiJ9.U2jp6RcJsMTATtmx36m3lg&zoomwheel=false#11/-22.908488662945476/-43.21305627186573'} locationName={'Rio de Janeiro/RJ'} />
}];

export default HomeViewModel;