import React from 'react';
import PageTemplate from '../components/templates/PageTemplate';
import PrivacyViewModel from '../model/PrivacyViewModel';
import ThemeViewModel from '../model/ThemeViewModel';

function Privacy() {
    return <PageTemplate menuTheme={ThemeViewModel.light} sections={PrivacyViewModel} />;
}

export default Privacy;
