import { useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ThemeViewModel from '../../model/ThemeViewModel';
import RoutesViewModel from '../../model/RoutesViewModel';

function MainMenu({ theme }) {
    const { pathname } = useLocation();

    return (
        <Navbar expand="lg" fixed="top" className={`${(theme === ThemeViewModel.dark ? 'bg-black' : 'bg-white')} z-2`}>
            <Container fluid>
                <Navbar.Brand href="/" className='m-0 p-0'>
                    <h5 className={`logo m-0 p-0 ${(theme === ThemeViewModel.dark ? 'text-white' : '')}`}>
                        Vinícius Stutz
                        <small className={(theme === ThemeViewModel.dark ? 'text-white' : '')}>Código limpo, elegante e coeso</small>
                    </h5>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link
                            className={pathname === RoutesViewModel.Home.path ? 'active' : ''}
                            href={RoutesViewModel.Home.path}>{RoutesViewModel.Home.name}</Nav.Link>
                        <Nav.Link
                            className={pathname === RoutesViewModel.Bookmarks.path ? 'active' : ''}
                            href={RoutesViewModel.Bookmarks.path}>{RoutesViewModel.Bookmarks.name}</Nav.Link>
                        <Nav.Link
                            className={pathname === RoutesViewModel.Opensource.path ? 'active' : ''}
                            href={RoutesViewModel.Opensource.path}>{RoutesViewModel.Opensource.name}</Nav.Link>
                        <Nav.Link
                            className={`btn ${(theme === ThemeViewModel.dark ? 'btn-light' : 'btn-dark')} pt-2 pb-2 ps-4 pe-4`}
                            href={RoutesViewModel.Contact.path} >{RoutesViewModel.Contact.name}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MainMenu;