import ThemeViewModel from "./ThemeViewModel";

const PrivacyViewModel = [{
    theme: ThemeViewModel.light,
    name: 'Política de privacidade',
    htmlText: <>
        <h3>O que você vai ver aqui?</h3>
        <p className='lh-base'>Este documento descreve como as informações pessoais são coletadas, usadas e compartilhadas quando você visita ou realiza interações neste Site, que serve como um canal de comunicação para a divulgação do marketing pessoal do titular.Este Site atua como um currículo dinâmico online e conta com serviços de terceiros, como Google™ Analytics para análise de tráfego, Tawk.io para atendimento em tempo real, Jotform para formulário de contato, Raindrop para compartilhar favoritos e Google™ para hospedagem.</p>
        <hr className="mt-5 mb-5" />
        <h3>Disposições gerais</h3>
        <p className='lh-base'>Esta Política de Privacidade visa esclarecer como é coletado, utilizado, armazenado e protegido cada um dos seus dados pessoais quando você acessa e utiliza este Site. Ela se aplica a todos os usuários do Site, incluindo aqueles que apenas navegam, bem como aqueles que se cadastram para usar os serviços disponíveis.</p>
        <h3>Dados coletados</h3>
        <h4>Dados de navegação:</h4>
        <ul>
            <li>Endereço IP: usado para identificar a localização geográfica do usuário e melhorar a experiência de navegação.</li>
            <li>Dados do navegador e do sistema operacional: coletados para otimizar o Site para diferentes dispositivos e navegadores.</li>
            <li>Páginas visitadas, links clicados e tempo de permanência: utilizados para entender como os usuários interagem com o Site e melhorar seu conteúdo.</li>
        </ul>
        <h4>Dados de contato:</h4>
        <ul>
            <li>Nome: fornecido quando o usuário preenche o formulário de contato.</li>
            <li>Endereço de e-mail: coletado para responder ao contato do usuário ou enviar newsletters (opcional).</li>
            <li>Mensagem: conteúdo da mensagem enviada pelo usuário.</li>
        </ul>
        <h4>Cookies:</h4>
        <p>Pequenos arquivos de texto armazenados no dispositivo do usuário para lembrar suas preferências e personalizar sua experiência no Site.</p>
        <h3>Uso dos dados</h3>
        <p>Os dados coletados são usados para:</p>

        <ul>
            <li>Melhorar a experiência de navegação: personalizar o conteúdo do Site, otimizar o desempenho e solucionar problemas técnicos.</li>
            <li>Fornecer os serviços: responder ao contato do usuário, enviar newsletters (opcional) e gerenciar o formulário de contato.</li>
            <li>Analisar o tráfego: entender como os usuários interagem com o Site, melhorar seu conteúdo e direcionar campanhas de marketing.</li>
        </ul>
        <h3>Compartilhamento de dados</h3>
        <p>Os dados coletados não são vendidos ou compartilhados com terceiros para fins de marketing. No entanto, eles podem ser compartilhados com:</p>

        <ul>
            <li>Google Analytics: para análise de tráfego.</li>
            <li>Tawk.io: para atendimento em tempo real.</li>
            <li>Jotform: para gerenciamento do formulário de contato.</li>
            <li>Raindrop: para compartilhamento de favoritos.</li>
        </ul>
        <h3>Armazenamento e segurança dos dados</h3>
        <p>Os dados coletados são armazenados em servidores seguros do Google™, que implementa medidas de segurança técnicas e administrativas para proteger os dados contra acessos não autorizados, uso indevido, divulgação, alteração ou destruição.</p>
        <h3>Direitos do usuário</h3>
        <p>O usuário tem os seguintes direitos em relação aos seus dados pessoais:</p>

        <ul>
            <li>Acesso: solicitar uma cópia dos seus dados pessoais.</li>
            <li>Correção: solicitar a correção de dados incorretos ou incompletos.</li>
            <li>Exclusão: solicitar a exclusão dos seus dados pessoais.</li>
            <li>Limitação do tratamento: solicitar a suspensão do uso dos seus dados pessoais.</li>
            <li>Oposição ao tratamento: solicitar que seus dados pessoais não sejam mais usados para fins de marketing.</li>
            <li>Portabilidade: solicitar que seus dados pessoais sejam transferidos para outro controlador.</li>
        </ul>

        <p>Para exercer qualquer um desses direitos, o usuário pode entrar em contato através do formulário de contato disponível no Site.</p>
        <h3>Alterações na Política de Privacidade</h3>
        <p>Esta Política de Privacidade pode ser alterada a qualquer momento. As alterações serão publicadas no Site e entrarão em vigor a partir da data de publicação.</p>
        <h3>Contato</h3>
        <p>Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato através do formulário de contato disponível no Site.</p>
        <hr />
        <small>Data da última atualização: 01 de abril de 2024</small>
    </>
}];

export default PrivacyViewModel;