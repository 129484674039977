import React from 'react';
import { Link } from 'react-router-dom';

function IconLink({ name, link, title, external, cssClass }) {
    if (external) {
        return (
            <a href={link} id={`icon-${name}`} target="_blank" rel="noopener noreferrer" title={title} className={cssClass}>
                <i className={`bi bi-${name}`}></i>
            </a>
        );
    }

    return (
        <Link to={link} id={`icon-${name}`} title={title} className={cssClass}>
            <i className={`bi bi-${name}`}></i>
        </Link>
    );
}

export default IconLink;
