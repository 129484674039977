import React from 'react';
import PageTemplate from '../components/templates/PageTemplate';
import TermsViewModel from '../model/TermsViewModel';
import ThemeViewModel from '../model/ThemeViewModel';

function Terms() {
    return <PageTemplate menuTheme={ThemeViewModel.light} sections={TermsViewModel} />;
}

export default Terms;
