import React from 'react';
import IconLink from '../atoms/IconLink';

function SocialIcons({ icons }) {
    return (
        <ul className="list-inline">
            {icons.map((icon, index) => (
                <li className="list-inline-item" key={index}>
                    <IconLink
                        name={icon.name}
                        link={icon.link}
                        title={icon.title}
                        external={icon.external}
                        cssClass={icon.class} />
                </li>
            ))}
        </ul>
    );
}

export default SocialIcons;
