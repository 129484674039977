import ThemeViewModel from "./ThemeViewModel";

const terms = '/terms';
const privacy = '/privacy';

const ContactViewModel = [{
    theme: ThemeViewModel.dark,
    name: 'Agora é só dizer o que tem em mente...',
    htmlText: <>
        <p className='lh-base'>Ao preencher e enviar este formulário, você demonstra automaticamente estar em acordo com os <a href={terms}>termos de uso</a> e com as <a href={privacy}>políticas de privacidade</a> deste site.</p>
        <div className="embed-responsive embed-responsive-16by9">
            <iframe
                id="JotFormIFrame-240568382333054"
                className="form"
                title="Formulário de contato"
                onLoad={window.parent.scrollTo(0, 0)}
                allowtransparency="true"
                allow="geolocation; microphone; camera; fullscreen"
                src="https://form.jotform.com/viniciusstutz/contato"
                frameBorder="0"
                scrolling="no"
            >
            </iframe>
        </div>
    </>
}];

export default ContactViewModel;