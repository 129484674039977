import React from "react";
import Loading from "./Loading";

function Maps({ url, locationName }) {
    return (
        <div className="embed-responsive embed-responsive-16by9 position-relative">
            <Loading label={'Carregando mapa, aguarde…'} isFixed={true} />
            <iframe
                className='maps'
                src={url}
                scrolling="no"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title={locationName}>
            </iframe>
        </div>
    );
}

export default Maps;