import Bookmarks from "../pages/Bookmarks";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import OpenSource from "../pages/OpenSource";
import Privacy from "../pages/Privacy";
import Terms from "../pages/Terms";

const RoutesViewModel = {
    Home: {
        name: 'Início',
        path: '/',
        element: <Home />
    },
    Contact: {
        name: 'Contato',
        path: '/contact',
        element: <Contact />
    },
    Bookmarks: {
        name: 'Coleções',
        path: '/bookmarks',
        element: <Bookmarks />
    },
    Opensource: {
        name: 'Open Source',
        path: '/opensource',
        element: <OpenSource />
    },
    Terms: {
        name: 'Termos de uso',
        path: '/terms',
        element: <Terms />
    },
    Privacy: {
        name: 'Política de privacidade',
        path: '/privacy',
        element: <Privacy />
    }
};

export default RoutesViewModel;