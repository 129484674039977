import React from "react";
import { Container, Row, Col, Stack } from 'react-bootstrap';
import SocialIcons from '../organisms/SocialIcons'
import ThemeViewModel from "../../model/ThemeViewModel";
// import IconLinkText from "../atoms/IconLinkText";
import Scroll from "../../utils/Scroll";
import IconLink from "../atoms/IconLink";

function Footer({ theme, icons }) {
    let bgClass = theme === ThemeViewModel.dark ? 'bg-black' : '';

    const handleScroll = () => Scroll.toTop();

    return (
        <footer className={`pt-5 pb-3 ${bgClass}`}>
            <Container>
                <Row>
                    <Col md='auto'>
                        <h5 className='logo text-white text-start' onClick={handleScroll} title="Para o alto e avante!">
                            Vinícius Stutz
                            <small className='text-white-50'>Código limpo, elegante e coeso</small>
                        </h5>
                    </Col>
                    <Col xl xxl></Col>
                    <Col>
                        <Stack gap={0}>
                            <div className="m-0 p-0"><SocialIcons icons={icons} /></div>
                            <div className="m-0 p-0"><small>Ilustrações gentilmente cedidas por <a href='https://storyset.com/' target='_blank' rel='nonoopener noreferrer'>Storyset</a>.</small></div>
                            <div className="m-0 p-0"><small>Página hospedada com tecnologia Google™.</small></div>
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    <hr className='mt-4' />
                    <Col className='text-center'>
                        <small>
                            vinici.us.com © 1983-2024 ・ Todos os direitos reservados. <br />
                            Feito com <IconLink name={'code-slash'} link={'https://react.dev'} title={'ReactJS'} external={true} cssClass={'m-2 align-middle'} /> e <IconLink name={'earbuds'} link={'https://www.last.fm/user/stutz83'} title={'Música'} external={true} cssClass={'m-2 align-middle'} /> no Rio de Janeiro
                        </small>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;