import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Scroll from '../../utils/Scroll';
import Icon from '../atoms/Icon';
import CenteredModal from './CenteredModal';

function Header() {
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');

    const handleShowModal = (title, body) => {
        setModalTitle(title);
        setModalBody(body);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <header className="vh-100 d-flex justify-content-center align-items-center p-5">
            <Row>
                <Col>
                    <h1 className='text-center lead animate__animated animate__pulse'>
                        Eu sou o <strong onClick={() => Scroll.toElementById('who')}>Vinícius</strong>, um analista <em>fullstack</em> certificado pela Microsoft<sup>™</sup>, com <strong onClick={() => Scroll.toElementById('when')}>20 anos de experiência</strong> no desenvolvimento web, desktop e mobile, com código <strong onClick={() => handleShowModal('Código limpo', 'Escrevo um código fácil de entender, com nomes de variáveis, classes, funções e métodos descritivos, comentários claros e ausência de duplicação de código. Sigo um padrão de formatação consistente, facilitando a leitura e manutenção.')}>limpo</strong>, <strong onClick={() => handleShowModal('Código elegante', 'Meus códigos são simples e bem estruturados. Utilizam abordagens simples e concisas para resolver problemas complexos, adotando bons padrões e evitando soluções confusas, desnecessariamente complicadas. Escrevo um código eficiente e evito o uso de recursos desnecessários.')}>elegante</strong> e <strong onClick={() => handleShowModal('Código coeso', 'Meus códigos costumam ter uma única responsabilidade bem definida para cada classe ou função. Cada componente do código deve se preocupar apenas com uma tarefa específica, facilitando a manutenção e reutilização do código. Meu código também se comunica bem com outros componentes, com uma interface clara e consistente.')}>coeso</strong>.
                    </h1>
                    <CenteredModal title={modalTitle} body={modalBody} show={showModal} onHide={handleCloseModal} />
                </Col>
            </Row>
            <div className='position-absolute bottom-0 start-50 translate-middle-x'>
                <button onClick={() => Scroll.toElementById('who')} className='animate__animated animate__bounce animate__infinite animate__slower btn btn-link link-dark link-opacity-25 link-underline-opacity-0 link-underline-opacity-0-hover'>
                    <Icon name={'chevron-compact-down'} />
                    <span className='visually-hidden sr-only' role='status'>Saiba mais…</span>
                </button>
            </div>
        </header>
    );
}

export default Header;