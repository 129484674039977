import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const CenteredModal = ({ title, body, show, onHide }) => {
    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton className='bg-black' >
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-black'>{body}</Modal.Body>
                <Modal.Footer className='bg-black'>
                    <Button variant="primary" size="lg" onClick={onHide}>
                        Entendi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CenteredModal;