import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RoutesViewModel from './model/RoutesViewModel';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path={RoutesViewModel.Contact.path}
          element={RoutesViewModel.Contact.element} />
        <Route
          path={RoutesViewModel.Bookmarks.path}
          element={RoutesViewModel.Bookmarks.element} />
        <Route
          path={RoutesViewModel.Opensource.path}
          element={RoutesViewModel.Opensource.element} />
        <Route
          path={RoutesViewModel.Terms.path}
          element={RoutesViewModel.Terms.element} />
        <Route
          path={RoutesViewModel.Privacy.path}
          element={RoutesViewModel.Privacy.element} />
        <Route
          path={RoutesViewModel.Home.path}
          element={RoutesViewModel.Home.element} />
      </Routes>
    </Router>
  );
}

export default App;
