const Scroll = {
    toTop: () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },
    toNext: (elementRef) => {
        if (elementRef !== null) {
            const elementHeight = elementRef.current.offsetHeight;
            const nextElement = elementRef.current.nextElementSibling;

            if (nextElement) {
                window.scrollTo({
                    top: elementHeight,
                    behavior: 'smooth'
                });
            }
        }
    },
    toElementById: (elementID) => {
        const element = document.getElementById(elementID);

        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth'
            });
        }
    }
};

export default Scroll;